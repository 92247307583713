/* Single Project Page */
.project {
  width: 100%;
  height: auto; /* Changed from fixed height to auto for flexibility */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #1a1a2e; /* Dark navy background */
  padding: 80px 0 50px; /* Adds space to prevent navbar overlap */
}

/* Project Title */
.project h1 {
  margin: 20px; /* Reduced margin since padding is added */
  color: #ffc857; /* Gold for consistency */
  font-size: 40px;
  font-weight: bold;
}

/* Project Image */
.project img {
  height: 400px;
  border-radius: 12px;
  box-shadow: 0px 6px 15px rgba(255, 200, 87, 0.2); /* Soft glow effect */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.project img:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 20px rgba(255, 200, 87, 0.4);
}

/* Project Description */
.project p {
  font-size: 24px;
  color: #e5e7eb; /* Light text for readability */
  padding-inline: 5%;
  text-align: center;
}

/* Icons */
.project svg {
  font-size: 60px;
  color: #ffc857; /* Matches gold accent */
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.project svg:hover {
  transform: scale(1.1);
  color: #e9b44c;
}

/* Links */
.project a {
  font-size: 20px;
  color: #e5e7eb;
  padding-inline: 10px;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
}

.project a:hover {
  color: #ffc857;
}
