body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  font-size: 24px;
  margin-top: 0;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

p {
  margin: 10px 0;
}

ul {
  margin: 10px 0;
  padding-left: 20px;
}

/* Definition list */
dl {
  margin: 0;
  padding: 0;
}

dt {
  font-weight: bold;
  margin-top: 10px;
}

dd {
  margin-left: 20px;
}

/* Link styles */
a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
