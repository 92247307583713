.footer {
  width: 100%;
  height: 200px;
  background: #21325e;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  margin-top: 0; /* Remove the unnecessary margin */
  flex-shrink: 0; /* Ensures footer stays at the bottom */
}

.socialMedia svg {
  color: white;
  margin: 20px;
  font-size: 70px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.socialMedia svg:hover {
  transform: scale(1.1);
  color: #e9d35b;
}

.footer p {
  color: white;
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .footer svg {
    font-size: 50px;
  }
}
