/* Navbar */
.navbar {
  width: 100%;
  height: 80px;
  background: #21325e;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between toggle and links */
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

/* Centered Links */
.links {
  flex-grow: 1; /* Makes it take up available space */
  display: flex;
  align-items: center;
  justify-content: center; /* Centers the links */
  gap: 20px;
}

/* Navbar Links */
.navbar a {
  color: white;
  text-decoration: none;
  font-size: 22px;
  font-weight: bold;
  padding: 10px 15px;
  transition: color 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.navbar a:hover {
  color: #e9d35b;
  transform: scale(1.1);
}

/* Toggle Button for Mobile */
.toggleButton {
  display: none; /* Hidden on desktop */
}

.toggleButton button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 24px;
  padding: 10px;
}

/* Mobile Menu Open */
#open {
  height: 100vh;
  background: #21325e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#open .links {
  flex-direction: column;
  gap: 30px;
  margin-top: 20px;
}

#open a {
  width: 100%;
  text-align: center;
  padding: 15px;
  font-size: 24px;
}

/* Responsive Navbar */
@media only screen and (max-width: 900px) {
  .navbar {
    height: 70px;
    padding: 0 15px;
  }

  .navbar a {
    font-size: 20px;
    padding: 8px 12px;
  }
}

@media only screen and (max-width: 600px) {
  .toggleButton {
    display: flex;
    align-items: center;
  }

  .toggleButton svg {
    font-size: 40px;
  }

  .links {
    display: none;
  }

  #close a {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .toggleButton {
    display: none;
  }

  .hiddenLinks {
    display: none;
  }

  #open {
    height: 80px;
  }

  #open .links {
    flex-direction: row;
  }
}
