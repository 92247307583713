/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", Arial, sans-serif;
  background-color: #1a1a2e;
  color: #f0f0f0;
}

/* Home Section */
.home {
  width: 100%;
  align-items: center;
  text-align: center;
}

/* About Section */
.about {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #21325e;
  color: #f0f0f0;
  padding: 20px;
}

.about h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 80px;
  font-weight: bold;
  color: #e9d35b;
  margin-bottom: 15px;
}

/* Description Text */
.about .prompt {
  width: 50%;
  font-size: 28px;
  line-height: 1.5;
  color: #f0f0f0;
  margin-bottom: 30px;
}

/* Resume Button */
.resume-button {
  background: linear-gradient(135deg, #e9d35b, #d4af37);
  color: #21325e;
  padding: 14px 28px;
  font-size: 20px;
  font-weight: bold;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.resume-button:hover {
  background: linear-gradient(135deg, #d4af37, #b8860b);
  transform: translateY(-3px);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.4);
}

.resume-button:active {
  transform: translateY(0);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

/* Social Icons */
.social-icons {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.prompt svg {
  font-size: 50px;
  margin: 5px;
  color: #f0f0f0;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.prompt svg:hover {
  transform: scale(1.1);
  color: #e9d35b;
}

/* Skills Section */
.skills {
  font-size: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1a1a2e;
  padding: 50px 0;
}

.skills h1 {
  color: #e9d35b;
  font-size: 42px;
  margin-bottom: 30px;
}

.skills .list {
  list-style: none;
  width: 60%;
  padding: 20px;
}

.list span {
  font-size: 22px;
  line-height: 1.6;
  color: #f0f0f0;
}

/* Skill Items */
.skills .list .item {
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 15px;
  transition: transform 0.3s ease-in-out;
}

.skills .list .item:hover {
  transform: scale(1.02);
}

/* Mobile Responsiveness */
@media only screen and (max-width: 768px) {
  .about h2 {
    font-size: 50px;
  }

  .about .prompt {
    font-size: 20px;
    width: 80%;
  }

  .resume-button {
    font-size: 18px;
    padding: 12px 20px;
  }

  .prompt svg {
    font-size: 40px;
  }

  .skills {
    font-size: 28px;
    text-align: center;
  }

  .skills .list {
    width: 85%;
  }

  .list span {
    font-size: 18px;
  }
}
